<template>
    <div>
        <div class="table_content">
            <commonTitle></commonTitle>
                <el-form :model="ruleForm" :rules="rules" :disabled="true" ref="ruleForm" label-width="100px"
                         class="demo-ruleForm">
                    <el-form-item label="机构编号" prop="orgCard">
                        <el-input v-model="ruleForm.orgCard" placeholder="请输入机构编号"></el-input>
                    </el-form-item>
                    <el-form-item label="机构名称" prop="orgName">
                        <el-input v-model="ruleForm.orgName" placeholder="请输入机构名称"></el-input>
                    </el-form-item>
                    <el-form-item label="法定代表人" prop="legPerson">
                        <el-input v-model.number="ruleForm.legPerson" autocomplete="off"
                                  placeholder="请输入法定代表人"></el-input>
                    </el-form-item>
                    <el-form-item label="联系电话" prop="phone">
                        <el-input v-model.number="ruleForm.phone" autocomplete="off" placeholder="请输入联系电话"></el-input>
                    </el-form-item>
                    <el-form-item label="办公地址" prop="busAddress">
                        <el-input v-model.number="ruleForm.busAddress" autocomplete="off"
                                  placeholder="请输入机构办公地址"></el-input>
                    </el-form-item>
                    <el-form-item label="邮箱" prop="mail">
                        <el-input v-model.number="ruleForm.mail" autocomplete="off" placeholder="请输入小区建筑面积"></el-input>
                    </el-form-item>
                    <el-form-item label="建立时间" prop="estTime">
                        <el-col :span="24">
                            <el-date-picker type="date" placeholder="选择小区建立时间" v-model="ruleForm.estTime"
                                            value-format="yyyy-MM-dd"
                                            style="width: 100%;"></el-date-picker>
                        </el-col>
                    </el-form-item>

                    <!--<el-form-item>
                         <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>

                     </el-form-item>-->
                </el-form>
                <!--            <div class="back1">-->
                <!--                <el-button type="primary" @click="back()">返回</el-button>-->
                <!--            </div>-->
        </div>
    </div>
</template>

<script>
    import commonTitle from "../table/commonTitle";

    export default {
        name: "qyInfo",
        components: {
            commonTitle
        },
        data() {
            return {
                currentTime: new Date().getFullYear() + '年' + (new Date().getMonth() + 1) + '月' + new Date().getDate() + '日 ' +
                    new Date().getHours() + ":" + new Date().getMinutes(), // 获取当前时间
                ruleForm: {
                    orgId: '', //机构编号
                    orgCard: '',  //机构编号
                    orgName: '', //机构名称
                    legPerson: '',        //法人
                    phone: '',  //电话
                    estTime: '', //建立时间
                    mail: '',//邮箱
                    busAddress: '',//办公地址
                    orgType: '',//机构类型
                },
                rules: {
                    orgCard: [
                        {required: true, message: '请输入机构编号', trigger: 'blur'},
                        //  {min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur'}
                    ],
                    orgName: [
                        {required: true, message: '请输入机构名称', trigger: 'change'}
                    ],
                    legPerson: [
                        {required: true, message: '请输入法人', trigger: 'change'}
                    ],
                    phone: [
                        {required: true, message: '请输入联系电话', trigger: 'change'}
                    ],
                    mail: [
                        {required: true, message: '请输入电子邮箱', trigger: 'change'}
                    ],
                    busAddress: [
                        {required: true, message: '请输入办公地址', trigger: 'blur'},
                    ],
                    orgType: [
                        {required: true, message: '请输入选择机构类型', trigger: 'change'},
                    ],
                    estTime: [
                        {required: true, message: '请选择机构成立时间', trigger: 'change'}
                    ]
                },
                url: this.$Config.base_server,
                orgType: []
            };
        }, methods: {
            back() {
                this.$router.go(-1);
            },
            selectOne(event, item) {
            }
            , submitForm(formName) {
                var _this = this;
                //立即创建按钮
                this.$refs[formName].validate(function (valid) {
                    if (valid) {
                        $.ajax({
                            url: _this.url + "/api-public/opeorg/update?access_token=" + localStorage.getItem("token"),
                            type: 'post',
                            data: JSON.stringify(_this.ruleForm),
                            contentType: 'application/json',
                            success: function (result) {
                                if (result.resp_code == 0) {
                                    //修改成功
                                    _this.$message({
                                        message: result.resp_msg,
                                        dangerouslyUseHTMLString: true,
                                        type: 'success',
                                        duration: 1000,
                                        onClose: function () {

                                        }
                                    });
                                } else {
                                    //修改失败
                                    _this.$message({
                                        message: result.resp_msg,
                                        dangerouslyUseHTMLString: true,
                                        type: 'warning'
                                    });
                                }
                            }
                        });
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            }, resetForm(formName) {
                //返回
                window.history.back(-1);
            }, changePwd() {
                //修改密码
                window.location.href = "changepwd.html";
            }
        }, created() {
            const _this = this;
            //查询当前机构
            $.get(this.url + '/api-public/opeorg/findOpeOrgByUserId/' + JSON.parse(localStorage.getItem("user")).id + "?access_token=" + localStorage.getItem("token"), function (result) {
                _this.ruleForm = result;
            });
        }
    }
</script>

<style scoped>
    .el-form {
        width: 600px;
        margin: 0 auto;
    }

    .back1 {
        display: flex;
        justify-content: center;
    }
</style>